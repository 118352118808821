import { zh } from '@/utils/constant/i18n/zh.js';
import { en } from '@/utils/constant/i18n/en.js';

const languageList = {
    data() {
        return {
            languages: [
                {
                    title: '简体中文',
                    lang: 'zh'
                },
                {
                    title: 'English',
                    lang: 'en'
                }
            ]
        }
    }
};

const messages = {
    zh,
    en
};

export {
    languageList,
    messages
};