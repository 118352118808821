<template>
	<keep-alive>
		<router-view v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.title" :key='key'></router-view>
	</keep-alive>
	<router-view v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.title" :key='key'></router-view>

</template>

<script>
export default {
	name: "App",
	components: {},
	methods: {},
	computed: {
		key() {
			var that = this;
			if (that.$route.path != '/BeforeExam/curriculum') {
				return this.$route.name !== undefined ? this.$route.name + '?'+new Date() : this.$route +'?'+ new Date()
			}else{
				return '';
			}
		}
	},
	mounted() {
		//document.dispatchEvent(new Event('render-event'));
	}
};
</script>