const zh = {
    common: {
        homePage: '首页',
        tip: "提示",
        add: '添加',
        delete: '删除',
        edit: '编辑',
        save: '保存',
        saveContinue: '保存并继续',
        saveClose: '保存并关闭',
        search: '搜索',
        confirm: '确认',
        cancel: '取消',
        back: '返回',
        yes: '是',
        no: '否',
        enable: '启用',
        disable: '禁用',
        remark: '备注',
        sort: '排序号',
        selectPlaceholder: '请选择',
        minute: '分钟',
        time: '时间',
        startTime: '开始时间',
        endTime: '结束时间',
        startDate: '开始日期',
        endDate: '结束日期',
        state: '状态',
        size: '大小',
        import:'导入',
        export:'导出',
        download: '下载',
        text: '文本',
        value: '值',
        min: '最小值',
        max: '最大值',
        province: '省',
        city: '市',
        county: '区/县',
        street: '街道/乡/镇',
        selectDelMsg: '确认要删除选中的数据行吗？',
        delMsg: `确认要删除【{key}】吗？`,
        pageLang: 'zh-cn'
    },
    content: {
        imageUploader:{
            uploadBtn:'点此上传'
        },
        columnEdit: {
            seoTitle: '栏目SEO标题',
            seoKeywords: '栏目SEO关键词',
            seoDesc: '栏目SEO描述',
            bannerL: 'Banner图(大)',
            bannerS: 'Banner图(小)',
        },
        contentEdit: {
            inputRuleMsg: `请输入{inputLabel}`
        },
        contentList:{
            goBack:'返回上级列表',
            recycle:'数据回收站',
            history:'历史',
            selectCol:'查看列',
            selectColExport:'选择列导出',
            importData:'导入内容数据',
            uploadTxt:`拖动文件到这里 点击<em>导入</em>上传`,
            tip:`请参照模板填充数据：`,
            getTemplate:'获取模板文件',
            tip1:`导入的数据文件必须为可识别的Excel文件，后缀名为.xls、.xlsx，且数据文件大小不可大于20M`,
            tip2:`不导入"CreateDate"时，系统默认添加当前时间`,
            errMsg:`请选择导入文件`,
            errMsg2:`请选择excel数据文件`,
            errMsg3:`导出文件出错`
        },
        showColumnsDialog:{
            enterItemName:'输入项名',
            dataColumnName:'数据列名',
            selectErrMsg:'请至少选择1列'
        },
        contentHistory:{
            selectColumns:'查看列'
        },
        recycleList: {
            backList: '返回列表',
            restore: '恢复'
        },
        singleEdit: {
            inputRuleMsg: `请输入{inputLabel}`
        },
        siteEdit: {
            seoTitle: '站点SEO标题',
            seoKeywords: '站点SEO关键词',
            seoDesc: '站点SEO描述',
            bannerL: 'Banner图(大)',
            bannerS: 'Banner图(小)',
            copyright: '版权信息',
            ICP: 'ICP备案号'
        },
        contentLayout: {
            title: '内容管理'
        },
        index: {
            title: '请选择左侧站点和栏目'
        }
    },
    develop: {
        columnEditDialog: {
            colNameMsg: '请输入列名',
            dataTypeMsg: '请输入数据类型',
            lengthMsg: '请输入长度',
            remarkMsg: '请输入备注'
        },
        tableEdit: {
            title: '返回表模型列表',
            tabNameMsg: '请输入表名'
        },
        tableList: {
            addCol: '添加列',
            colName: '列名',
            dataType: '数据类型',
            length: '长度',
            oper: '操作',
            tabName: '表名',
            generateCode: '生成代码',
            manuItem1: '实体类',
            manuItem2: '服务类'
        },
        editorEdit: {
            title: 'UE编辑'
        },
        cascadeEdit: {
            title: '级联选择器',
            deep: '最深级别',
            multiple: '是否多选'
        },
        checkboxEdit: {
            title: '多选框'
        },
        radioEdit: {
            title: '单选框'
        },
        selectEdit: {
            title: '选择器',
            dataSource: '选择数据源',
            dataSourceMsg: '请选择数据源',
            chancelId: '频道ID',
            chancelIdMsg: '请输入频道ID',
            formId: '表单ID',
            enterFormId: '请输入表单ID',
            onlyNumber: '只能输入数字',
            labelName: '标签列名',
            enterLabelName: '请设置标签列名',
            valColumn: '值列名',
            pColName: '父级ID列名',
            enterParentCol: '请设置父级ID列名',
            loadMethod: '加载方式',
            enterLoadMethod: '请选择加载方式',
            oper: '操作',
            enterTxt: '请输入文本',
            enterVal: '请输入值'
        },
        inputNumberEdit: {
            title: '数字输入框',
            enterMin: '请输入最小值',
            enterMax: '请输入最大值',
            step: '步长',
            enterStep: '请输入步长'
        },
        inputTextEdit: {
            title: '输入框',
            minLength: '最小长度',
            enterMinLength: '请输入最小长度',
            maxLength: '最大长度',
            enterMaxLength: '请输入最大长度',
            placeholder: '占位符',
            multipleLines: '显示多行',
            minRows: '最小行数',
            enterMinRows: '请输入可见行数',
            maxRows: '最大行数',
            enterMaxRows: '请输入最大行数'
        },
        inputEdit: {
            dataColumn: '数据列',
            selectExistColumn: '选择已有列',
            available: '可用',
            used: '已使用',
            columnName: '列名',
            enterColumnName: '请输入列名',
            dataType: '数据类型',
            enterDataType: '请输入数据类型',
            length: '长度',
            enterLength: '请输入长度',
            showItem: '显示项',
            showInList: '列表中显示',
            input: '输入项',
            itemName: '输入项名称',
            enterName: '请输入名称',
            inputType: '输入类型',
            enterInputType: '请输入类型',
            gridLeftOffset: '栅格左偏移',
            enterGridLeftOffset: '请输入栅格左偏移',
            columnWidth: '栅格列宽',
            enterColumnWidth: '请输入栅格列宽',
            nameWidth: '名称宽度',
            enterNameWidth: '请输入名称宽度',
            readOnly: '是否只读',
            hidden: '是否隐藏',
            defaultVal: '默认值',
            inputTips: '输入提示',
            rules: '验证规则',
            required: '是否必填',
            unique: '唯一验证',
            regex: '验证正则',
            validErr: '验证错误提示'
        },
        formEdit: {
            backBtn: '返回表单模型列表',
            tModel: '表模型',
            enterTModel: '请输入表模型',
            formName: '表单名称',
            enterFormName: '请输入表单名称',
            mode: '管理模式',
            enterMode: '请输入管理模式',
            parentForm: '上级表单',
            linkText: '链接文字',
            enterLinkText: '请输入链接文字',
            linkParam: '链接参数',
            enterLinkParam: '请输入链接参数',
            addInput: '添加输入项',
            itemName: '输入项名称',
            columnName: '数据列名',
            inputType: '输入类型',
            listDisplay: '列表显示',
            isListDisplay: `列表<br />显示`,
            layoutWidth: '布局宽度',
            offsetWidth: `偏移<br />宽度`,
            readOnly: '是否只读',
            isReadOnly: `是否<br />只读`,
            hidden: '是否隐藏',
            isHidden: `是否<br />隐藏`,
            required: '是否必填',
            isRequired: `是否<br />必填`,
            unique: '唯一验证',
            oper: '操作'
        },
        formList: {
            formName: '表单名称',
            mode: '管理模式',
            linkText: '链接文字',
            linkParam: '链接参数',
            addChild: '添加下级表单',
            generateCode: '生成代码',
            VueFile: '前端vue文件',
            classFile: '后端类文件',
            classDiagram: '类图'
        },
        channelEdit: {
            parent: '上级站点/栏目',
            parentRule: '请选择上级站点/栏目',
            formType: '表单类别',
            formTypeRule: '请选择表单类别',
            channelName: '栏目名称',
            channelNameRule: '请输入栏目名称'
        },
        siteEdit: {
            siteName: '站点名称',
            siteNameRule: '请输入站点名称'
        },
        siteList: {
            addSite: '添加站点',
            addChannel: '添加栏目',
            name: '站点/栏目名',
            formName: '管理表单',
            addChildChannel: '添加下级栏目'
        },
        developLayout: {
            title: '开发设置',
            siteChannel: '站点栏目管理',
            formInput: '表单模型管理',
            table: '表模型管理'
        }
    },
    file: {
        fileIndex: {
            title: '文件管理',
            upload: '上传文件',
            fileName: '文件名',
            delMsg: `确认要删除，【{fileName}】文件吗？`,
            upDiaTitle: '选择上传文件',
            upDiaOperTip: `拖动文件到这里 点击<em>'上传文件'</em>上传`,
            upDiaRuleTip: `可上传的文件类型：`,
            imgFileTip: `图片文件：{imageExt} 。大小不超过：{imageSize} KB`,
            fileTip: `文件类型：{fileExt} 。大小不超过：{fileSize} KB`,
            uploadSuccessMsg: `文件上传成功`,
            uploadErrTitle: `上传失败`,
            nullFileMsg: `请选择上传文件`,
            beforeValid1: `无法上传 {suffix} 类型的文件 ：{filename} `,
            beforeValid2: `{suffix} 类型的文件不能超过 {size} KB ：{filename} `,
            beforeValid3: `{suffix} 类型的文件不能超过 {size} KB ：{filename} `
        }
    },
    manager: {
        login: {
            title: '登录',
            loginNameRule: '请输入登录名',
            pwdRule: '请输入密码',
            loginBtn: '登 录'
        },
        roleEdit: {
            backRoleList: '角色管理列表',
            roleNameRule: '请输入角色名',
            menuPermission: '菜单权限',
            menuName: '菜单名称',
            operPermission: '操作权限',
            oper: '操作',
            selectAll: '全选',
            inverse: '反选',
            noneAll: '取消',
            channelPermissions: '站点栏目权限',
            channelName: '栏目名称',
            actionPermissions: '权限操作'
        },
        roleList: {
            roleName: '角色名称'
        },
        managerEdit: {
            backUserList: '用户管理列表',
            loginName: '登录名',
            loginNameRule: '请输入登录名',
            pwd: '密码',
            pwdRule: '请输入密码',
            pwdTip: '不输入则不修改密码',
            role: '所属角色',
            roleRule: '请输入所属角色',
            realNameRule: '请输入真实姓名'
        },
        managerTabMenu: {
            user: '用户管理',
            role: '角色管理'
        },
        managerList:
        {
            disTip: '确认要禁用吗？',
            userName: '用户名',
            realName: '真实姓名',
            roleName: '所属角色',
            disConfirm: `确认要禁用【{loginName}】吗？`
        }
    },
    security: {
        logList: {
            clearTip: '确认清理 1 年前的日志吗？',
            clearConfirm: '确认清理',
            clearBtn: '清除1年前日志',
            logUser: '操作人',
            logType: '日志类型',
            operLog: '操作日志',
            errLog: '错误日志',
            loginLog: '登录日志',
            content: '详细内容',
            details: '查看详细',
            logTitle: '日志详细'
        },
        securitySetting: {
            loginTime: '管理员登录有效时长',
            ipWhiteList: '管理员登录IP白名单',
            tip: '以换行为分隔，每行代表一个IP白名单'
        },
        securityLayout: {
            securitySettings: '安全设置',
            userManager: '用户管理',
            securityConfig: '安全配置',
            sysLog: '系统日志'
        }
    },
    setting: {
        statisticSetting: {
            statisticsScripts: '统计脚本',
        },
        serviceSetting: {
            CustomerScripts: '客服脚本'
        },
        mailSetting: {
            sendParam: '发送参数',
            smtp: 'SMTP地址',
            port: '端口',
            sendEmailAddress: '发件人邮箱地址',
            userName: '用户名',
            pwd: '口令',
            pwd_ph: 'smtp邮箱服务密码',
            testSend: '测试收信',
            receive: '收件邮箱',
            send: '发送',
            rulePort: '请输入端口号',
            ruleEmail: '邮箱地址不正确'
        },
        sensitiveWord: {
            multipleAdd: {
                tip: '以换行为分隔，每行代表一个敏感词'
            },
            edit: {
                backBtn: '敏感词列表',
                validMsg: '请输入敏感词'
            },
            list: {
                singleAdd: '单个添加',
                bulkAdd: '批量添加',
                confirmDel: '确认要删除吗？',
                sensitiveWord: '敏感词'
            }
        },
        regionSettings: {
            regionName: '地区名'
        },
        fileSettings: {
            storageDirectory: '存放目录',
            detectDirReadAndWrite: '检测目录读写权限',
            pictures: '图片设置',
            imageType: '图片类型',
            imageCapacity: '图片容量',
            files: '文件设置',
            fileType: '文件类型',
            fileCapacity: '文件容量',
            saveRules: {
                saveDirectory: '请输入文件存放目录!'
            },
            checkUploadDirMsg: `<strong>读取：{r}</strong><br/><strong>写入：{w}</strong>`
        },
        systemSetting: {
            historicalRecords: '历史记录数量',
        },
        settingLayout: {
            sysInfo: '系统信息',
            statisticSetting: '流量统计',
            serviceSetting: '在线客服',
            emailSettings: '邮箱设置',
            sensitiveWord: '内容敏感词',
            regionSettings: '地区设置',
            uploadSettings: '上传设置',
            systemSetting: '系统设置'
        }
    },
    personalInfo:{
        currUser:'当前用户',
        role:'所属角色',
        lastLogin:'上次登录',
        lastChangePwd:'上次修改密码',
        personInfo:'个人信息',
        changePwd:'修改密码',
        LogOut:'退出登录',
        confirmLogout:'确认退出登录吗?'
    },
    index: {
        controlCentre: '控制中心',
        personalInfo: '个人信息',
        dataSubtotals: '数据小计',
        customerService: '用户服务',
        incrementalTrend: '增量走势',
        stockDistribution: '存量分布',
        systemInfo: '系统信息'
    },
    personInfo: {
        realName: '真实姓名',
        email: '电子邮箱',
        operLang: '操作语言',
        pageQuantity: '每页显示数量'

    },
    mainMenu: {
        menu1: "控制中心",
        menu2: "内容管理",
        menu3: "文件管理",
        menu4: "系统设置",
        menu5: "安全设置",
        menu6: "开发设置",
        personInfo: "个人信息",
        logout: "退出登录",
        logoutText: "确认退出登录吗?"
    }
}

export {
    zh
};