import { request } from "@/utils/request";

 

export function Login(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/Login",
    params,
  });
}
export function LoginByUserName(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/LoginByUserName",
    params,
  });
}

export function GetCurrLogin(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/GetCurrLogin",
    params,
  });
}
export function GetCurrLoginM(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/GetCurrLoginM",
    params,
  });
}
export function Modify(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/ModifyFront",
    params,
  });
}

//修改密码
export function ModifyPassword(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/ModifyPassword",
    params,
  });
}

export function Register(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/Register",
    params,
  });
}

export function GetItemByOpenId(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/GetItemByOpenId",
    params,
  });
}


export function GetItemByOpenIdExt(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/GetItemByOpenIdExt",
    params,
  });
}

export function LoginCode(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/LoginCode",
    params,
  });
}

export function WechatCallBack(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/WechatCallBack",
    params,
  });
}

export function WxCallBack(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/WxCallBack",
    params,
  });
}

export function BindPhone(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/Student/BindPhone",
    params,
  });
}