// import Vue from "vue";
// import Vuex from "vuex";

import { createStore } from "vuex";

import common from "./modules/common";

// Vue.use(Vuex);

const store = new createStore({
  modules: {
    common,
  },
});

export default store;
