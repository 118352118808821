const en = {
    common: {
        homePage: 'home',
        tip: "tips",
        add: 'add',
        delete: 'delete',
        edit: 'edit',
        save: 'save',
        saveContinue: 'save and continue',
        saveClose: 'save and Close',
        search: 'search',
        confirm: "confirm",
        cancel: 'cancel',
        back: 'back',
        yes: 'yes',
        no: 'no',
        enable: 'enable',
        disable: 'disable',
        remark: 'remark',
        sort: 'sort number',
        selectPlaceholder: 'please select',
        minute: 'minute',
        time: 'time',
        startTime: 'start time',
        endTime: 'end time',
        startDate: 'start date',
        endDate: 'end sate',
        state: 'state',
        size: 'size',
        import: 'import',
        export: 'export',
        download: 'download',
        text: 'text',
        value: 'value',
        min: 'min',
        max: 'max',
        province: 'province',
        city: 'city',
        county: 'district/county',
        street: 'street/township',
        selectDelMsg: 'Confirm you want to delete the selected data rows?',
        delMsg: `Are you sure you want to delete [{key}]? `,
        pageLang: 'en'
    },
    content: {
        imageUploader: {
            uploadBtn: 'Click here upload'
        },
        columnEdit: {
            seoTitle: 'Column SEO Title',
            seoKeywords: 'Column SEO keywords',
            seoDesc: 'Column SEO Desc',
            bannerL: 'Banner (large)',
            bannerS: 'Banner (small)',
        },
        contentEdit: {
            inputRuleMsg: 'Please enter {inputLabel}'
        },
        contentList: {
            goBack: 'Go Back Parent',
            recycle: 'Data Recycle',
            history: 'history',
            selectCol: 'select columns',
            selectColExport: 'Select Export Columns',
            importData: 'Import data',
            uploadTxt: 'Drag the file here, click <em>Import</em> and upload',
            tip: 'Please refer to the template to populate the data:',
            getTemplate: 'Get template file',
            tip1: 'The imported data file must be a recognizable Excel file with the suffix .xls, . xlsx, and the data file size cannot be larger than 20M',
            tip2: 'When not importing "CreateDate", the system adds the current time by default',
            errMsg: 'Please select the import file',
            errMsg2: 'Please select excel data file',
            errMsg3: 'Error exporting file'
        },
        showColumnsDialog: {
            enterItemName: 'Enter Item Name',
            dataColumnName: 'Data column name',
            selectErrMsg: 'Please select at least 1 column'
        },
        contentHistory: {
            selectColumns: 'select columns'
        },
        recycleList: {
            backList: 'Back List',
            restore: 'restore'
        },
        singleEdit: {
            inputRuleMsg: 'Please enter {inputLabel}'
        },
        siteEdit: {
            seoTitle: 'Site SEO Title',
            seoKeywords: 'Site SEO keywords',
            seoDesc: 'Site SEO Desc',
            bannerL: 'Banner (large)',
            bannerS: 'Banner (small)',
            copyright: 'Copyright',
            ICP: 'ICP'
        },
        contentLayout: {
            title: 'Content Management'
        },
        index: {
            title: 'Please select a site and column on the left'
        }
    },
    develop: {
        columnEditDialog: {
            colNameMsg: 'Please enter a column name',
            dataTypeMsg: 'Please enter a data type',
            lengthMsg: 'Please enter length',
            remarkMsg: 'Please enter a comment'
        },
        tableEdit: {
            title: 'Table Model List',
            tabNameMsg: 'Please enter a table name'
        },
        tableList: {
            addCol: 'Add column',
            colName: 'Column Name',
            dataType: 'data type',
            length: 'length',
            oper: 'operation',
            tabName: 'Table Name',
            generateCode: 'generate code',
            manuItem1: 'entity class',
            manuItem2: 'service class'
        },
        editorEdit: {
            title: 'UEditor'
        },
        cascadeEdit: {
            title: 'Cascade Selector',
            deep: 'Deep',
            multiple: 'multi-select'
        },
        checkboxEdit: {
            title: 'check box'
        },
        radioEdit: {
            title: 'radio box'
        },
        selectEdit: {
            title: 'selector',
            dataSource: 'Data Source',
            dataSourceMsg: 'Please select data source',
            chancelId: 'channel ID',
            chancelIdMsg: 'Please enter channel ID',
            formId: 'Form ID',
            enterFormId: 'Please enter form ID',
            onlyNumber: 'Only numbers can be entered',
            labelName: 'Label Column',
            enterLabelName: 'Please set label column name',
            valColumn: 'Value column',
            pColName: 'Parent column',
            enterParentCol: 'Please set parent column name',
            loadMethod: 'load method',
            enterLoadMethod: 'Please select loading method',
            oper: 'Operation',
            enterTxt: 'Please enter text',
            enterVal: 'Please enter value'
        },
        inputNumberEdit: {
            title: 'Number box',
            enterMin: 'Please enter the minimum value',
            enterMax: 'Please enter the maximum value',
            step: 'step size',
            enterStep: 'Please enter step size'
        },
        inputTextEdit: {
            title: 'input box',
            minLength: 'min length',
            enterMinLength: 'Please enter minimum length',
            maxLength: 'max length',
            enterMaxLength: 'Please enter maximum length',
            placeholder: 'placeholder',
            multipleLines: 'is multiple',
            minRows: 'min rows',
            enterMinRows: 'Please enter the number of visible rows',
            maxRows: 'max rows',
            enterMaxRows: 'Please enter maximum number of rows'
        },
        inputEdit: {
            dataColumn: 'data column',
            selectExistColumn: 'existing col',
            available: 'available',
            used: 'used',
            columnName: 'col name',
            enterColumnName: 'Please enter a column name',
            dataType: 'data type',
            enterDataType: 'Please enter data type',
            length: 'length',
            enterLength: 'Please enter length',
            showItem: 'Show Item',
            showInList: 'Show in list',
            input: 'Input Item',
            itemName: 'Item Name',
            enterName: 'Please enter a name',
            inputType: 'Input type',
            enterInputType: 'Please enter type',
            gridLeftOffset: 'left offset',
            enterGridLeftOffset: 'Please enter the left offset of the grid',
            columnWidth: 'width',
            enterColumnWidth: 'Please enter raster column width',
            nameWidth: 'tag width',
            enterNameWidth: 'Please enter name width',
            readOnly: 'read-only',
            hidden: 'hidden',
            defaultVal: 'default value',
            inputTips: 'input tips',
            rules: 'validation rules',
            required: 'required',
            unique: 'unique verif',
            regex: 'verify regex',
            validErr: 'error msg'
        },
        formEdit: {
            backBtn: 'Return to form model list',
            tModel: 'table model',
            enterTModel: 'Please enter table model',
            formName: 'form name',
            enterFormName: 'Please enter a form name',
            mode: 'manager mode',
            enterMode: 'Please enter admin mode',
            parentForm: 'parent form',
            linkText: 'link text',
            enterLinkText: 'Please enter the link text',
            linkParam: 'link param',
            enterLinkParam: 'Please enter link parameters',
            addInput: 'Add input',
            itemName: 'Enter Item Name',
            columnName: 'column name',
            inputType: 'Input Type',
            listDisplay: 'List Display',
            isListDisplay: 'display in list',
            layoutWidth: 'layout width',
            offsetWidth: 'offset <br /> width',
            readOnly: 'read-only',
            isReadOnly: 'read-only',
            hidden: 'hidden',
            isHidden: ' hidden',
            required: 'required',
            isRequired: 'required',
            unique: 'unique',
            oper: 'Oper'
        },
        formList: {
            formName: 'form name',
            mode: 'management mode',
            linkText: 'link text',
            linkParam: 'link parameters',
            addChild: 'Add subordinate form',
            generateCode: 'generate code',
            VueFile: 'front-end vue file',
            classFile: 'backend class file',
            classDiagram: 'class diagram'
        },
        channelEdit: {
            parent: 'parent site/column',
            parentRule: 'Please select parent site/column',
            formType: 'Form Category',
            formTypeRule: 'Please select a form category',
            channelName: 'column name',
            channelNameRule: 'Please enter column name'
        },
        siteEdit: {
            siteName: 'site name',
            siteNameRule: 'Please enter a site name'
        },
        siteList: {
            addSite: 'Add Site',
            addChannel: 'Add Column',
            name: 'site/column name',
            formName: 'Manage forms',
            addChildChannel: 'Add child columns'
        },
        developLayout: {
            title: 'Development Settings',
            siteChannel: 'Site column ',
            formInput: 'Form Model ',
            table: 'Table Model '
        }
    },
    file: {
        fileIndex: {
            title: 'File Management',
            upload: 'upload file',
            fileName: 'filename',
            delMsg: `Are you sure you want to delete, [{fileName}] file? `,
            upDiaTitle: 'Choose to upload file',
            upDiaOperTip: `Drag the file here Click <em>'Upload File'</em>`,
            upDiaRuleTip: 'Uploadable file types:',
            imgFileTip: `Image file: {imageExt} . Size no more: {imageSize} KB`,
            fileTip: `File type: {fileExt} . Size no more: {fileSize} KB`,
            uploadSuccessMsg: `file upload successful`,
            uploadErrTitle: `upload failed`,
            nullFileMsg: `Please select upload file`,
            beforeValid1: `Unable to upload {suffix} type file: {filename} `,
            beforeValid2: `{suffix} files cannot exceed {size} KB :{filename} `,
            beforeValid3: `{suffix} files cannot exceed {size} KB :{filename} `
        }
    },
    manager: {
        login: {
            title: 'login',
            loginNameRule: 'Please enter login name',
            pwdRule: 'Please enter password',
            loginBtn: 'login'
        },
        roleEdit: {
            backRoleList: 'Role Management List',
            roleNameRule: 'Please enter role name',
            menuPermission: 'menu permission',
            menuName: 'menu name',
            operPermission: 'Operation Permission',
            oper: 'operation',
            selectAll: 'select all',
            inverse: 'inverse',
            noneAll: 'None at all',
            channelPermissions: 'Site channel permissions',
            channelName: 'channel name',
            actionPermissions: 'Permission Actions'
        },
        roleList: {
            roleName: 'role name'
        },
        managerEdit: {
            backUserList: 'User Management List',
            loginName: 'login name',
            loginNameRule: 'Please enter login name',
            pwd: 'password',
            pwdRule: 'Please enter password',
            pwdTip: 'Do not change password if you do not enter',
            role: 'role',
            roleRule: 'Please enter your role',
            realNameRule: 'Please enter your real name'
        },
        managerTabMenu: {
            user: 'User Manager',
            role: 'Role Manager'
        },
        managerList:
        {
            disTip: 'Are you sure you want to disable it?',
            userName: 'user name',
            realName: 'real name',
            roleName: 'Role',
            disConfirm: `Confirm you want to disable [{loginName}]? `
        },
    },
    security: {
        logList: {
            clearTip: 'Confirm cleaning up logs from 1 year ago?',
            clearConfirm: 'confirm cleanup',
            clearBtn: 'Clear 1 year old log',
            logUser: 'operator',
            logType: 'log type',
            operLog: 'operation log',
            errLog: 'error log',
            loginLog: 'login log',
            content: 'details',
            details: 'View details',
            logTitle: 'Log Verbose'
        },
        securitySetting: {
            loginTime: 'admin login time',
            ipWhiteList: 'admin IP white list',
            tip: 'Separated by a new line, each line represents an IP address whitelist'
        },
        securityLayout: {
            securitySettings: 'Security settings',
            userManager: 'User Management',
            securityConfig: 'Security Configuration',
            sysLog: 'System Log'
        }
    },
    setting: {
        statisticSetting: {
            statisticsScripts: 'Statistics scripts',
        },
        serviceSetting: {
            CustomerScripts: 'Agent scripts'
        },
        mailSetting: {
            sendParam: 'Send Params',
            smtp: 'SMTP Address',
            port: 'PORT',
            sendEmailAddress: 'Send Email Address',
            userName: 'User Name',
            pwd: 'Password',
            pwd_ph: 'SMTP email service password',
            testSend: 'Test Send',
            receive: 'Receive Email',
            send: 'Send',
            rulePort: 'port is required',
            ruleEmail: 'The email address is incorrect'
        },
        sensitiveWord: {
            multipleAdd: {
                tip: 'Separated by a swap, each line represents a sensitive word'
            },
            edit: {
                backBtn: 'Sensitive words list',
                validMsg: 'Please enter a sensitive word'
            },
            list: {
                singleAdd: 'Aingle Add',
                bulkAdd: 'Bulk Add',
                confirmDel: 'Are you sure you want to delete?',
                sensitiveWord: 'Sensitive words'
            }
        },
        regionSettings: {
            regionName: 'region name'
        },
        fileSettings: {
            storageDirectory: 'Storage Directory',
            detectDirReadAndWrite: 'Detect dir read and write permissions',
            pictures: 'Pictures',
            imageType: 'Image Type',
            imageCapacity: 'Image Capacity',
            files: 'Files',
            fileType: 'File Type',
            fileCapacity: 'File Capacity',
            saveRules: {
                saveDirectory: 'Please enter the file storage directory !'
            },
            checkUploadDirMsg: `<strong>Reader: {r}</strong><br/><strong>Write: {w}</strong>`
        },
        systemSetting: {
            historicalRecords: 'historical records'
        },
        settingLayout: {
            sysInfo: 'system info',
            statisticSetting: 'statistic setting',
            serviceSetting: 'service setting',
            emailSettings: 'email settings',
            sensitiveWord: 'sensitive word',
            regionSettings: 'Region Settings',
            uploadSettings: 'Upload Settings',
            systemSetting: 'System Setting'
        }
    },
    personalInfo: {
        currUser: 'Current User',
        role: 'role',
        lastLogin: 'Last Login',
        lastChangePwd: 'Last changed password',
        personInfo: 'Personal Information',
        changePwd: 'change password',
        LogOut: 'Log out',
        confirmLogout: 'Confirm logout?'
    },
    index: {
        controlCentre: 'control centre',
        personalInfo: 'personal info',
        dataSubtotals: 'data subtotals',
        customerService: 'customer service',
        incrementalTrend: 'incremental trend',
        stockDistribution: 'stock distribution',
        systemInfo: 'system info'
    },
    personInfo: {
        realName: 'Real name',
        email: 'Email',
        operLang: 'Oper language',
        pageQuantity: 'Page Quantity'
    },
    mainMenu: {
        menu1: "Control Center",
        menu2: "Content Management",
        menu3: "File Management",
        menu4: "System Settings",
        menu5: "Security Settings",
        menu6: "Development Settings",
        personInfo: "Person Info",
        logout: "Logout",
        logoutText: "Confirm logout?"
    }
}

export {
    en
};