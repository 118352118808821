String.prototype.trimStart = function (start) {
  if (this.indexOf(start) == 0) {
    return this.substring(start.length);
  }
  return this;
};

String.prototype.trimEnd = function (end) {
  if (this.lastIndexOf(end) == this.length - end.length) {
    return this.substring(0, this.lastIndexOf(end));
  }
  return this;
};

String.prototype.trimStartEnd = function (fix) {
  return this.trimStart(fix).trimEnd(fix).toString();
};

String.prototype.toInt = function (defaultValue) {
  return parseInt(this) || defaultValue;
};

String.prototype.toBool = function () {
  return this.toLowerCase() == "true" || this == "1";
};
