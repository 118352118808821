const enumLogType = {
  Login: "登录日志",
  Operation: "操作日志",
  Error: "错误日志",
};

const enumDataType = {
  Int: "整型",
  Long: "长整型",
  Decimal: "货币型",
  Bit: "位型",
  Date: "日期型",
  Time: "时间型",
  DateTime: "日期时间型",
  Varchar: "字符串型",
  Text: "长文本型",
};

const enumInputType = {
  Input: "输入框",
  InputNumber: "数字输入框",
  DatePicker: "日期选择器",
  TimePicker: "时间选择器",
  DateTimePicker: "日期时间选择器",
  Switch: "开关切换器",
  Select: "选择器",
  Radio: "单选框",
  Checkbox: "多选框",
  ColorPicker: "取色器",
  UploadImage: "上传图片",
  UploadFile: "上传文件",
  Cascade: "级联选择器",
  Editor: "编辑器",
};

// 不同的数据列类型，对应不同的输入项类型
const columnInputType = {
  Int: [enumInputType.InputNumber],
  Long: [enumInputType.InputNumber],
  Decimal: [enumInputType.InputNumber],
  Bit: [enumInputType.Switch],
  Date: [enumInputType.DatePicker],
  Time: [enumInputType.TimePicker],
  DateTime: [enumInputType.DateTimePicker],
  Varchar: [
    enumInputType.Input,
    enumInputType.Select,
    enumInputType.Radio,
    enumInputType.Checkbox,
    enumInputType.ColorPicker,
    enumInputType.UploadImage,
    enumInputType.UploadFile,
    enumInputType.Cascade,
  ],
  Text: [enumInputType.Editor],
};

const enumManageMode = {
  SingleEdit: "单篇编辑",
  ListEdit: "列表编辑",
  ListDetail: "列表详情",
  TreeEdit: "树形编辑",
};

export {
  enumLogType,
  enumDataType,
  enumInputType,
  columnInputType,
  enumManageMode
};
