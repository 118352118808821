//i18n加载本地化
import { createI18n } from 'vue-i18n';
import { messages } from '@/utils/constant/i18n/local'; //自定义本地化页面资源

//seo优化
import MetaInfo from 'vue-meta-info';

const i18n = new createI18n({
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages: messages,
    fallbackLocale: 'zh',
    locale: localStorage.getItem('eip_plus_lang') || 'zh'
});

import VueUeditorWrap from 'vue-ueditor-wrap';

import zhCn from "element-plus/es/locale/lang/zh-cn";
import English from "element-plus/es/locale/lang/en";

export default {
    install(app) {

        /******************第三方库引用*******************/
        //i18n
        app.use(i18n);

        //UEditor
        app.use(VueUeditorWrap);

        //seo优化
        app.use(MetaInfo);
        
        /******************自定义全局组件*******************/


        /******************全局方法*******************/
        //组件注入方式，inject: ['方法名'] ，可在data()中访问

        //调用element-local切换本地化资源
        app.provide('getElementLocal', function (lang) {
            switch (lang) {
                case "zh-cn":
                    return zhCn;
                case "en":
                    return English;
                default:
                    return zhCn;
            }
        });

        //根据i18n路径返回验证消息
        app.provide('ruleMessage', function (i18nPath, format) {
            return this.$t(i18nPath, format);
        });
    }
}
