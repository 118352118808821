import { createApp } from "vue";

import App from "./App.vue";

const app = createApp(App);


import "@/assets/style/animate.scss";
import "@/assets/style/global.scss";
import "@/assets/style/main.scss";
import "@/assets/style/animate.scss";
import "@/assets/style/css.scss";
import "@/assets/style/slick.scss";
import "@/assets/style/style.scss";
import "@/assets/style/px.scss";

import "@/assets/js/jquery.slick.js";
import "swiper/css/swiper.min.css";

// wowjs
import animated from 'animate.css';
import 'wowjs/css/libs/animate.css';
app.use(animated);

/** Element Plus */
import ElementPlus from "element-plus";
import { ElMessage } from "element-plus";

import "element-plus/dist/index.css";

app.use(ElementPlus, { size: "default", });

/** Element Plus Icon */
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}

/** material icons */
import "material-icons/iconfont/material-icons.css";

/** store */
import store from "./store";

/** router */
import router from "./router.js";

/** 菜单选中 */
router.beforeEach(async (to, from, next) => {
	next();

	// 网页标题
	if (to.meta.title) {
		document.title = to.meta.title;
	}

	// 选中菜单
	// console.log("primaryMenuId:", to.meta.primaryMenuId);
	if (to.meta.primaryMenuId) {
		store.state.common.primaryMenuId = to.meta.primaryMenuId;
	}
	// console.log("store.state.common.primaryMenuId", store.state.common.primaryMenuId);
	if (to.meta.secondaryMenuId) {
		store.state.common.secondaryMenuId = to.meta.secondaryMenuId;
	}
	// console.log("store.state.common.tertiaryMenuId", store.state.common.tertiaryMenuId);
	if (to.meta.tertiaryMenuId) {
		store.state.common.tertiaryMenuId = to.meta.tertiaryMenuId;
	}
});

/** 路由跳转 */
app.config.globalProperties.$pushTo = (url) => {
	if (router.path != url) {
		router.push(url);
	}

	// 页面重置到顶部
	document.body.scrollIntoView();
};

app.config.globalProperties.$replaceTo = (url) => {
	// console.log('this.$route.path', this.$route.path);
	// console.log('url', url);
	if (router.path != url) {
		router.replace(url);
	}
	// 页面重置到顶部
	document.body.scrollIntoView();
};

/** 消息提醒 */
app.config.globalProperties.$successMessage = (content, duration = 1000) => {
	ElMessage({
		message: content,
		type: "success",
		grouping: true,
		duration: duration
	});
};

app.config.globalProperties.$errorMessage = (content, duration = 1000) => {
	ElMessage({
		message: content,
		type: "error",
		grouping: true,
		duration: duration
	});
};

import axiosJson from 'axios';

/* 读取外部配置文件 */

axiosJson.get(location.origin + '/config.json').then((res) => {
	//console.log(res.data)
	app.config.globalProperties.$config = res.data;

	app.config.globalProperties.$uploadfiles = () => {
		return (res.data.uploadpath);
	}

	/** 咨询客服 */
	app.config.globalProperties.$onlineconSultation = () => {
		window.open((res.data.onlineconSultation), "_blank");
	}

	//微信授权登录回调地址（兰麦考前）
	app.config.globalProperties.$WechatCallBack = () => {
		return (res.data.WechatCallBack);
	}

	//微信授权登录回调地址（行业培训）
	app.config.globalProperties.$WechatHypxCallBack = () => {
		return (res.data.WechatHypxCallBack);
	}

	//微信公众号授权登录回调地址（兰麦考前）
	app.config.globalProperties.$WxCallBack = () => {
		return (res.data.WxCallBack);
	}

	app.config.globalProperties.$bindImg = (img) => {
		if (img == undefined || img == "") {
			return "";
		} else {

			if (img.indexOf('http') > -1) {
				return img;
			}

			return res.data.uploadpath + img;
		}
	}

	app.config.globalProperties.$sign = () => {
		return (res.data.sign);
	}
});


import { GetCurrLoginM } from "@/api/student";

app.config.globalProperties.$setCurrAdminKaoqian = () => {
	GetCurrLoginM({
		tokenStr: localStorage.getItem("studenttoken")
	}).then((res) => {
		return res.body;
	}).catch((err) => {
		setTimeout(() => {
			this.$pushTo("/BeforeExam/loginPhone");
		}, 1000)
	});
}

app.config.globalProperties.$tolinkurl = (linkurl) => {
	if (linkurl != '' && linkurl != null) {
		if (linkurl.indexOf('http') > -1) {
			window.open(linkurl);
		} else {
			this.$pushTo(linkurl);
		}
	}
}

app.config.globalProperties.$LoginM = () => {
	return ""
}

/** 自定义的常量 */
import "./utils/constant/enumeration";
/** 自定义的数据类型扩展 */
import "./utils/extension/stringExtension";
import "./utils/extension/numberExtension";


//自定义插件,提供全局调用资源
import plugins from './plugins';

app.use(store).use(router).use(plugins).mount("#app");