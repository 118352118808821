const state = {
  primaryMenuId: "",
  secondaryMenuId: "",
  tertiaryMenuId: "",
};

const mutations = {
  SET_PRIMARY_MENU_ID: (state, data) => {
    state.primaryMenuId = data;
  },
  SET_SECONDARY_MENU_ID: (state, data) => {
    state.secondaryMenuId = data;
  },
  SET_TERTIARY_MENU_ID: (state, data) => {
    state.tertiaryMenuId = data;
  },
};

const actions = {
  getPrimaryMenuId({ commit }, e) {
    return new Promise(() => {
      commit("SET_MAIN_MENU_ID", e);
    });
  },
  getSecondaryMenuId({ commit }, e) {
    return new Promise(() => {
      commit("SET_SECONDARY_MENU_ID", e);
    });
  },
  getTertiaryMenuId({ commit }, e) {
    return new Promise(() => {
      commit("SET_TERTIARY_MENU_ID", e);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
